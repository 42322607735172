import React from 'react'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import {Container, Col, Row} from "react-bootstrap"

const links = () => {
    return (
        <Layout>
                <SEO title="Links zu aktiven Projekten" />
            <Image />
            <h1>Links</h1>
            <h2 className="text-center">Tanzschulen</h2>
            <Container>
                <Row>
                    <Col sm><a href="https://www.latinsalsa.de" target="_blank" rel="noreferrer">Latinsalsa - Tanzschule für Salsa & Bachata in Köln</a></Col>
                    <Col sm>Tanzschule für Salsa, Bachta, Cumbia und weitere lateinamerikanische Tänze. Kurse, Einzelstunden, Workshops.
                    <p>Mit Drupal entwickelt.</p></Col>
                </Row>
                </Container>

            
                <h2 className="text-center">Unterkünfte & Ferienhäuser</h2>
            <Container>
                <Row>
                    <Col sm><a href="https://www.casalapa.com" target="_blank" rel="noreferrer">casalapa.com - Übernachtungen in Costa Rica</a></Col>
                    <Col sm>Saubere, preiswerte Unterkünfte in Alajuela, Costa Rica in der Nähe des Flughafens.<br />Mit Drupal entwickelt.</Col>
                </Row>


                <Row className="stdMarginTop">
                    <Col sm><a href="" target="_blank" rel="noreferrer">Le Royaume de Rhea et William - Ferienhaus in Frankreich</a></Col>
                    <Col sm>Traumhaus in Frankreich, Vendays-Montalivet. 5 Minuten zum Meer.<br />In Vorbereitung</Col>
                </Row>
                </Container>


                <h2 className="text-center">Reisen</h2>
            <Container>
                <Row>
                    <Col sm><a href="https://www.latinsalsa-reisen.de" target="_blank" rel="noreferrer">Latinsalsa-Reisen.de - Reisen nach Costa Rica</a></Col>
                    <Col sm>Entdecke eines der schönsten Länder der Erde - und lerne authentisch Salsa, Bachata, Cumba und Reggaeton vor, während und nach der Reise.<br />Mit Drupal entwickelt.</Col>
                </Row>
                </Container>

                <h2 className="text-center">Veranstaltungen</h2>
            <Container>
                <Row>
                    <Col sm><a href="https://www.latinsalsa-jga.de" target="_blank" rel="noreferrer">Latinsalsa-jga.de - Junggesellinnen-Abschied in Köln</a></Col>
                    <Col sm>Mit Tänzen und Choreografien deiner Wahl.<br />Mit Drupal entwickelt.</Col>
                </Row>
                </Container>

            <h2 className="text-center">Blogs</h2>
            <Container>
                <Row>
                    <Col sm><a
                        href="https://construire-avec-mca-france.netlify.app"
                        target="_blank"
                        hrefLang="fr"
                        rel="noreferrer">Construction d'une maison avec MCA (blog)</a></Col>
                    <Col sm>Experiénces avec la construction d'une maison avec Maisons de la Côte Antlantique (MCA) en France.<br />Developé avec Gatsby, Gitlab et Netlify.</Col>
                </Row>

                <Row className="stdMarginTop">
                    <Col sm><a
                        href="https://building-with-mca-france.netlify.app/"
                        target="_blank"
                        hrefLang="en"
                        rel="noreferrer">Building a house with MCA in France (blog)</a></Col>
                    <Col sm>Blog sharing experiences with the construction of a house with Maisons de la Côte Antlantique (MCA) in France.<br />Developped with Gatsby using Gitlab, deployed and hosted with Netlify.</Col>
                </Row>


                <Row className="stdMarginTop">
                    <Col sm><a
                        href="https://hausbau-mit-mca-frankreich.netlify.app/"
                        target="_blank"
                        hrefLang="de"
                        rel="noreferrer">Hausbau mit MCA in Frankreich (blog)</a></Col>
                    <Col sm>Blog über Erfahrungen beim Hausbau mit Maisons de la Côte Antlantique (MCA) in Frankreich.<br />Geschrieben mit Gatsby, Gitlab, gehostet mit Netlify.</Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default links
